<template>
  <div v-if="loading" class="w-m-body flex flex-center items-center justify-center full-height">
    <sl-loading />
  </div>
  <form v-else @submit.prevent="save" class="w-m-content negocio-window-form">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin no-select" style="color: #293e52">Criar Negócio</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <e-row mr>
        <e-col style="width: 50%; border-right: #CCCCCC 1px solid; padding-right: 20px">
          <div style="min-height: 400px" class="font-11">
            <erp-s-field class="m-t" label="Pessoa *">
              <autocomplete :search="searchPessoa" :handleNew="onHandleNew" ref="pessoaNomeInput" v-model="pessoa"
                icon="user" required />
              <div class="m-t-xs" v-if="pessoa && pessoa.id">
                <a @click="gotoPerson(pessoa)"><i class="fa fa-eye"></i> Visualizar pessoa</a>
              </div>
            </erp-s-field>

            <erp-s-field class="m-t" label="Organização">
              <autocomplete :search="searchOrganizacao" ref="orgNomeInput" v-model="negocio.organizacao"
                icon="building" />
            </erp-s-field>

            <erp-s-field class="m-t" label="Tags">
              <autocomplete-multiple loadOnFocus :search="searchPersonTags" v-model="negocio.tags"
                placeholder="Nenhuma" />
            </erp-s-field>
            <erp-s-field class="m-t" label="Título *">
              <erp-input v-model="negocio.titulo" required />
            </erp-s-field>
            <e-row mr mb>
              <e-col class="m-r-xs">
                <erp-s-field class="m-t" label="Valor *">
                  <erp-input placeholder="R$" v-model="negocio.valor" v-money="money" required />
                </erp-s-field>
              </e-col>
            </e-row>
            <erp-s-field class="m-t" label="Funil">
              <erp-select v-model="negocio.funil" :options="listFunis" />
            </erp-s-field>

            <erp-s-field class="m-t" label="Etapas do Funil *">
              <etapas-funil :etapas="funil.etapas" :value="negocio.funilEtapa"
                @update="((e) => negocio.funilEtapa = e)" />
            </erp-s-field>
            <e-row class="m-t" mr>
              <e-col>
                <erp-s-field class="m-t" label="Probabilidade % *">
                  <erp-input v-model="negocio.probabilidade" type="number" :step="1" :min="0" :max="100" required />
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Data Fechamento esperada">
                  <datetime-input input-class="app-input" date-type="date" date-format="##/##/####"
                    v-model.lazy="negocio.dataFechamentoEsperada" simpleBorder />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row>
              <e-col class="m-r-xs">
                <erp-s-field label="Cor">
                  <erp-input v-model="negocio.cor" type="color"></erp-input>
                </erp-s-field>
              </e-col>
            </e-row>
          </div>
        </e-col>
        <e-col v-if="!negocio.id" style="margin-left: 20px">
          <erp-s-field class="m-t" v-if="pessoa" label="Contato Pessoa">
            <erp-s-field label="Telefone" class="m-t">
              <e-row v-for="(telefone, index) in pessoa.phoneNumbers" :key="'phone-' + index">
                <e-col class="m-r-xs">
                  <erp-input :disable="!pessoa" v-model="telefone.phoneNumber" />
                </e-col>
                <e-col class="m-l-xs">
                  <tipo-telefone-select :disabled="!pessoa" required select-first no-label v-model="telefone.purpose" />
                </e-col>
                <e-col v-if="pessoa.phoneNumbers.length > 1" class="flex items-center" style="max-width: 20px">
                  <a v-if="!!pessoa" class="m-l-xs" @click="removeTelefone(telefone)">
                    <u-icon name="trash" type="fa" color="grey-7" />
                  </a>
                </e-col>
              </e-row>
              <div v-if="pessoa"><a @click="() => adicionarTelefone()" class="font-bold">+ Adicionar telefone</a></div>
            </erp-s-field>
            <erp-s-field label="E-mail" class="m-t">
              <e-row v-for="(email, index) in pessoa.emails" :key="'email-' + index">
                <e-col class="m-r-xs">
                  <erp-input :disable="!pessoa" v-model="email.email" />
                </e-col>
                <e-col class="m-l-xs">
                  <tipo-email-select :disabled="!pessoa" required select-first no-label v-model="email.purpose" />
                </e-col>
                <e-col v-if="pessoa.emails.length > 1" class="flex items-center" style="max-width: 20px">
                  <a v-if="!!pessoa" class="m-l-xs" @click="removeEmail(email)">
                    <u-icon name="trash" type="fa" color="grey-7" />
                  </a>
                </e-col>
              </e-row>
              <div v-if="!!pessoa"><a @click="() => adicionarEmail()" class="font-bold">+ Adicionar email</a></div>
            </erp-s-field>
          </erp-s-field>
          <div v-else>Selecione ou crie uma pessoa para visualizar os dados de contato</div>
        </e-col>
        <e-col v-else style="margin-left: 20px">
          <template v-if="negocio && negocio.id">
          <div v-if="pessoa" class="m-t">Para editar dados de contato e endereço acesse o cadastro de <a @click="gotoPerson(pessoa)">{{pessoa.name}}</a>.</div>
          <div class="m-t">Negócio criado em {{negocio.createdAt|formatDate}} por {{negocio.createdByUser}}</div>
          </template>
        </e-col>
      </e-row>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps :color="'white'" text-color="grey-8"
        class="b-radius-3px btn-white m-r-sm" />
      <u-btn :loading="saving" type="submit" label="Salvar configurações" no-caps :color="'primary'"
        class="b-radius-3px" />
    </div>
  </form>
</template>

<script>
import ERow from '../../../layout/components/Row'
import { ErpInput, ErpSField, ErpSelect } from 'uloc-vue-plugin-erp'
import ECol from '../../../layout/components/Col'
import Autocomplete from "@/components/layout/components/Autocomplete"
import searchOrganizacao from "@/components/pessoa/helpers/searchOrganizacao"
import searchTags from "@/components/pessoa/helpers/searchTags"
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"
import { mockTelefone } from "@/components/pessoa/mock/telefone"
import { mockEmail } from "@/components/pessoa/mock/email"
import TipoTelefoneSelect from "@/components/pessoa/components/include/TipoTelefoneSelect"
import TipoEmailSelect from "@/components/pessoa/components/include/TipoEmailSelect"
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import searchPessoa from "components/pessoa/helpers/searchPessoa"
import { convertRealToMoney, REAL_BRL } from "@/utils/money"
import { VMoney } from "v-money"
import EtapasFunil from "../inputs/EtapasFunil.vue"
import DatetimeInput from "@/reuse/input/Datetime"
import { newNegocio, updateNegocio, find } from '@/domain/negocio/services/negocio'
import { datePtToEn } from "@/utils/date"
import SlLoading from "components/layout/components/Loading";
import { find as findFunis } from "@/domain/negocio/services/funil"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"


export default {
  name: 'UsuarioWindowModern',
  mixins: [PersonMixin, GlobalPersonMixin],
  directives: { money: VMoney },
  components: {
    SlLoading,
    TipoEmailSelect,
    TipoTelefoneSelect,
    AutocompleteMultiple,
    Autocomplete,
    ErpInput,
    ErpSField,
    ErpSelect,
    DatetimeInput,
    EtapasFunil,
    ERow,
    ECol
  },
  props: ['options', 'router', 'type', 'id', 'funil', 'funis', 'newByEtapa'],
  data() {
    return {
      money: REAL_BRL,
      loading: true,
      saving: false,
      success: false,
      status: '',
      pessoa: null,
      negocio: {
        organizacao: null,
        tags: [],
        titulo: '',
        valor: 0,
        funil: null,
        funilEtapa: 1,
        dataFechamentoEsperada: null,
        cor: null
      }
    }
  },
  mounted() {
    this.getFunis()
    if (this.id) {
      this.load()
    } else {
      this.negocio.funil = this.funil.id
      this.loading = false
    }
    this.negocio.funilEtapa = this.newByEtapa[0].etapas[0].id ?? this.funil.etapas[0].id

  },
  computed: {
    listFunis() {
      return !!this.funis ? this.funis.map(p => {
        return {
          ...p,
          label: p.nome,
          value: p.id,
          active: null
        }
      }) : []
    }
  },
  methods: {
    onHandleNew(pessoa) {
     console.log(pessoa)
      this.$nextTick(() => {
        this.negocio.titulo = 'Negócio ' + this.pessoa.value.split(' ')[0]
        if(this.pessoa.isNew) {
          this.pessoa.phoneNumbers = [JSON.parse(JSON.stringify(mockTelefone))]
          this.pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
          this.pessoa.type = 1
          this.pessoa.name = this.pessoa.value
        }
      })
    },
    load() {
      this.loading = true
      find(this.id || this.negocio.id)
        .then(response => {
          this.negocio = this.prepareToEdit(response.data)
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .then()
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o negócio, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })

    },
    save() {
      this.saving = true
      var data = this.prepareToSave()

      let method = data.id ? updateNegocio(data.id, data) : newNegocio(data)
      method
        .then(({ data }) => {
          let isNew = !!!this.negocio.id
          this.negocio.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              this.saving = false
              this.success = false

              let title = 'Cadastro de Negócio'
              let message = 'Negócio cadastrado com sucesso!'
              if (!isNew) {
                title = 'Atualização do Negócio'
                message = 'Negócio atualizado com sucesso!'
              }
              this.$uloc.dialog({
                title: title,
                message: message,
                type: 'success'
              })
              // this.load()
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', this.negocio.id)
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', this.negocio.id)
              }
              this.$uloc.window.close(this.$root.wid)
            })
          }

          cbSuccess()

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.$nextTick(() => {
            this.saving = false
            this.alertApiError(error)
            // this.load()
          })
        })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.negocio))
      const valores = ['valor']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })

      if (data.valor === null || data.valor === '') {
        data.valor = 0
      }

      data.cor = data.cor?.replace('#', '')

      if (data.dataFechamentoEsperada) {
        data.dataFechamentoEsperada = datePtToEn(data.dataFechamentoEsperada)
      }
      data.pessoa = this.transformToSubmit(JSON.parse(JSON.stringify(this.pessoa)))
      if (!data.pessoa) {
        alert('Informe uma pessoa')
        this.saving = false
        return
      }
      if (data.pessoa && data.pessoa.id && data.id) {
        data.pessoa = null // Provisório
      }
      return data
    },
    prepareToEdit(dataPack) {
      const { pessoa, funil, funilEtapa, ...data } = dataPack
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.pessoaNomeInput.set(pessoa.name)
          this.pessoa = this.transformToView(pessoa);
          if (!!data.organizacao) {
            data.organizacao = this.transformToView(data.organizacao);
            this.$refs.orgNomeInput.set(data.organizacao.name)
          }
        })
      }, 100)
      data.tags = []
      if (pessoa && pessoa.tags) {
        data.tags = pessoa.tags.map(tag => {
          return tag.name
        })
        console.log('TAGS', data.tags)
      }
      data.funil = funil.id;
      console.log(dataPack)
      data.funilEtapa = funilEtapa.id;
      data.valor = 'R$ ' + this.$options.filters.moeda(dataPack.valor)
      const datas = ['dataFechamentoEsperada']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })
      return data
    },
    searchPessoa(terms, done) {
      searchPessoa(terms)
        .then(response => {
          const extra = {
            label: `Cadastrar "${terms}" como nova pessoa`,
            value: terms,
            isNew: true
          }
          let result = response.data.result.map(p => {
            return {
              ...p,
              label: p.name,
              value: p.name,
              active: null
            }
          })
          result.push(extra)
          done(result)
        }).catch(error => {
          this.alertApiError(error)
        })
    },
    searchOrganizacao(terms, done) {
      searchOrganizacao(terms)
        .then(response => {
          const extra = {
            label: `Adicionar "${terms}" como nova organização`,
            value: terms,
            isNew: true
          }
          let result = response.data.result.map(p => {
            return {
              ...p,
              label: p.name,
              value: p.name,
              active: null
            }
          })
          result.push(extra)
          done(result)
        }).catch(error => {
          this.alertApiError(error)
        })
    },
    searchPersonTags(terms, done) {
      searchTags(terms)
        .then(response => {
          const extra = {
            label: `Adicionar "${terms}" como nova tag`,
            value: terms,
            isNew: true
          }
          let result = response.data.result.map(p => {
            return {
              ...p,
              label: p.name,
              value: p.name,
              active: null
            }
          })
          result.push(extra)
          done(result)
        }).catch(error => {
          this.alertApiError(error)
        })
    },
    getFunis() {
      findFunis()
        .then(response => {
          this.funis = response.data
        })
        .catch(error => {
          this.alertApiError(error)
        })
    },
    gotoPerson(p) {
      let router = this.$router || this.router
      let routeData = router.resolve({ name: 'pessoa.show', params: { id: p.id } })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
